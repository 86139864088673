@import "@design";

.reports-list-item {
  display: block;
  padding: $gutter;
  border-top: 12px solid gray;
  &.status-Setup {
    border-top-color: $orange;
  }
  &.status-Completed {
    border-top-color: $green;
  }
  &.status-Locked {
    border-top-color: $blue;
  }
  &.status-Scheduled {
    border-top-color: $teal;
  }
  &.invalid {
    border-top-color: $red;
  }
  a {
    color: $gray-dark;
    .date {
      margin-bottom: 5px;
    }
    .detail-group {
      margin-bottom: 0.7em;
    }
    .detail-label {
      margin-bottom: 0;
      font-size: 0.8rem;
      color: $gray-body;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .detail {
      font-size: 1.1rem;
      color: $gray-dark;
    }
  }
}
